import * as React from 'react';
//import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios'

const baseApiUri = process.env.REACT_APP_API_URL;

export default function PortalAddIndicator(props) {

    const [selectedIndicator, setSelectedIndicator] = React.useState(0);
    //const [portalIndicators, setPortalIndicators] = React.useState([]);
    let indicatorIds = [];
    let portalIndicators = [];
    for(let i = 0; i < props.indicators.length; i++){
        let indicator = props.indicators[i];
        if(!indicatorIds.includes(indicator.id)){
            portalIndicators.push(indicator);
            indicatorIds.push(indicator.id);
        }
    }

    const handleIndicatorSelectChange = (event) => {
        setSelectedIndicator(event.target.value);
    };


    async function addIndicator(){

        console.log('ADD PROPS',props);
        console.log('VALUE',value);
        console.log('URL',url);
        console.log('LINK',link);
        console.log('ADDING: ', props.childEltName);
        console.log('ADDING TO: ',props.currentElement);
        console.log('ADDING TO ID: ', props.currentElementId);
        console.log('INDICATOR', selectedIndicator);
        //return true;
        
        if(selectedIndicator === 0 || selectedIndicator === ''){
            if(value !== '' && url !== ''){
                try{
                    let endpoint = `${baseApiUri}/portals/addindicator`;
                    let payload = {
                        elttype:props.childEltName,
                        eltval:value,
                        elturl:url,
                        eltlink: link,
                        eltparent:props.currentElementId
                    }
                    let elt = await axios.post(endpoint, payload,{withCredentials: true} );
                    console.log(elt);
                } catch(e){}
            }
           
        } else {
            try{
                let payload = {
                    elttype:props.childEltName,
                    eltval:value,
                    elturl:url,
                    eltlink: link,
                    eltparent:props.currentElementId,
                    eltid: selectedIndicator
                }
                let endpoint = `${baseApiUri}/portals/addindicatormapping`;
                let elt = await axios.post(endpoint, payload,{withCredentials: true} );
                console.log(elt);
            } catch(e){}
        }
        
        props.reload();
        props.addIndicatorClose();
    }

  const [value, setValue] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [link, setLink] = React.useState('');
 //console.log('TAB PROPS', props);
  //let elt = props.currentElement[0];
  //console.log('ELT', elt);

  const handleTextFieldChange = (event) => {
    setValue(event.target.value);
  };

  const handleUrlTextFieldChange = (event) => {
    setUrl(event.target.value);
  };

  const handleLinkTextFieldChange = (event) => {
    setLink(event.target.value);
  };


  return (
    <Box sx={{ width: '100%' }}>
     <div style={{margin:'20px'}}  >

         <TextField  style={{marginRight:'5px',marginTop:'5px'}} onChange={handleTextFieldChange}  id="id-indicator-title" label="indicator title" variant="outlined" />
         <TextField  style={{marginRight:'5px',marginTop:'5px'}} onChange={handleUrlTextFieldChange}  id="id-indicator-url" label="indicator url" variant="outlined" />
         <TextField  style={{marginRight:'5px',marginTop:'5px'}} onChange={handleLinkTextFieldChange}  id="id-indicator-link" label="indicator link" variant="outlined" />
            <Box sx={{ minWidth: 120,marginTop:'20px' }}>
                <FormControl fullWidth>
                    <InputLabel id="indicator-select-label">Or Select Existing Indicator</InputLabel>
                    <Select
                    labelId="indicator-select"
                    id="indicator-select"
                    value={selectedIndicator}
                    label="Indicator"
                    onChange={ handleIndicatorSelectChange}
                    >
                        <MenuItem key={"ind_0"} value='0'>
                                Select an indicator
                         </MenuItem>
                      {portalIndicators.map((option) => (
                            <MenuItem key={"ind_"+option.id} value={option.id}>
                                {option.title}
                            </MenuItem>
                        ))}   
                    
                    </Select>
                </FormControl>
                </Box>
         <Button style={{marginTop:'20px'}}variant="contained" onClick={addIndicator}>Add</Button>
         
      </div>
    </Box>
  );
}